.link-button {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 14px;
  font-weight: 600;
  color: $red;
  border: 2px solid $red;
  background: $white;
  @include box-shadow();

  &.small {
    padding: 5px 10px;
    font-weight: 400;
    border-radius: 8px;
  }

  &:hover, &:focus,
  &.small:hover, &.small:focus {
    @include box-shadow;
    text-decoration: none;
    background: $red;
    color: $white;
    border-color: $red;
  }
}
