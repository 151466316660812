/*
Theme Name: nhlsv5
Author: David Neubauer
Author URI: http://davidne.at
Description: Theme for https://neubauer-hls.at
Version: 5.0
Text Domain: neubauer-hls.at
*/

$red: #ff0202;
$red-dark: #d50500;
$grey: #f1f1f1;
$grey-dark: #5d5b5b;
$grey-highlight: #e8e8e8;
$white: #ffffff;

$font-size: 17px;
$font-size-2: 20px;
$max-width: 1140px;

$breakpoints: (
  'sm':  576px,
  'md': 768px,
  'lg':  992px,
  'xl':  1200px
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
 
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin transition($property: all) {
  transition: $property 0.15s ease;
}

@mixin box-shadow() {
  box-shadow: 7px 6px 20px -10px rgba(0,0,0,0.3);
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@import 'content';
@import 'btn';
@import 'hamburger';
@import 'header-nav';
@import 'card';
@import 'article';
@import 'stat';
@import 'feature';
@import 'comment';
@import 'search';
@import 'gallery';
@import 'footer';

html, body {
  font-size: $font-size;
}

h1 {
  line-height: 1.2;
}
