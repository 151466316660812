.stat {
  margin: 0 auto;
  text-align: center;
  padding: 20px 0 50px;
  position: relative;

  &:before {
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: 100% auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    opacity: 0.1;
    width: 100px;
    height: 100px;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
  }
}

.stat-erfahrung:before {
  background-image: url("assets/stat-erfahrung.png");
  width: 140px;
  height: 140px;
  margin-top: -80px;
  margin-left: -65px;
}

.stat-abgewickelt:before {
  background-image: url("assets/stat-abgewickelt-2.png");
  width: 80px;
  height: 80px;
  margin-top: -70px;
  margin-left: 70px;
}

.stat-mitarbeiter:before {
  background-image: url("assets/stat-mitarbeiter.png");
  width: 60px;
  height: 60px;
  margin-top: -55px;
  margin-left: 25px;
}

.stat-header {
  font-size: 40px;
  font-weight: 600;
}

.stat-text {
  font-size: 20px;
}
