.content-wrapper {
  min-height: 400px;
}

.content {
  max-width: $max-width;
  margin: 0 auto;
  padding: 20px;

  &.space-small {
    width: 100%;
  }

  &.space-x-small {
    width: 62%;
  }

  a {
    color: $red;
    border-bottom: 2px solid $red;
    text-decoration: none;

    &:hover {
      color: $grey-dark;
      text-decoration: none;
      border-color: $grey-dark;
    }
  }
}

@include breakpoint('md') {
  .content {
    &.space-small {
      width: 80%;
    }

    &.space-x-small {
      width: 62%;
    }
  }
}