.feature-list {
  padding: 20px 0 40px;
  margin: 0;
  margin: 0 auto;
}

.feature-box .feature-list-item {
  min-height: 340px;
}

.feature-list-item {
  list-style: none;
  padding: 0 0 60px;
  margin: 0;

  article {
    margin: 0 auto;
  }
}

.feature-article {
  ul {
    list-style: disc;
  }

  a {
    color: $red;
    border-bottom: 2px solid red;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: none;
      border-color: $grey-dark;
      color: $grey-dark;
    }
  }
}

.feature-article-img {
  background: $grey-dark;
  width: 100%;
  //padding-bottom: 56.25%;
  margin-bottom: 20px;
  padding-bottom: 59%;
  border-radius: 5px;
  background-size: cover;
}
