.header {
  padding: 30px 5px 10px;
}
 
.header-logo {
  text-align: center;
  width: 220px;
  margin: 0 0 0 15px;
  
  img {
    width: 100%;
  }
}

body.nav-is-active {
  overflow: hidden;
}

#toggle-hamburger {
  font-size: 0;
  position: fixed;
  top: 30px;
  right: 15px;
  z-index: 9999;
  width: 60px;
  height: 60px;
  background: $white;
  border-radius: 100%;
  padding: 10px;
}

.header-mainnav {
  list-style: none;
  background: white;
  padding: 0;
  margin: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 98;
  padding: 70px 30px;
  text-align: center;

  .nav-is-active & {
    display: block;
  }

  a {
    color: $grey-dark;
    font-size: 30px;
    line-height: 2.4;

    &:hover, &:focus {
      color: $red;
    }
  }

  .active {
    position: relative;

    a {
      text-decoration: underline;
    }
  }
}

.header-subnav {
  display: none;
}

.nav-search {
  a, .searchform-cancel {
    display: none;
  }
}

.searchform {
  border: 2px solid $grey-dark;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 30px 0;
  background: $grey;

  label {
    position: absolute;
    color: $grey-dark;
    font-size: 20px;
    font-weight: 400;
    top: 50%;
    left: 15px;
    line-height: 40px;
    margin-top: -20px;
    z-index: 1;
  }
  
  input {
    position: relative;
    z-index: 2;
    background: none;
    color: $grey-dark;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    padding: 15px 15px;
    font-size: 20px;
    padding-right: 100px;
    background: transparent;
   }

   button {
     position: absolute;
     z-index: 3;
     right: 10px;
     top: 0;
     bottom: 0;
     border: 0;
     width: 90px;
     background: $grey;
     color: $grey-dark;
     border-top: 2px solid $grey;
     border-bottom: 2px solid $grey;

     &:hover, &:focus {
        border-bottom-color: $grey-dark;
     }
   }
}

.nav-facebook a {
  font-size: 20px;
  padding-bottom: 40px;
}

.header-nav {
  &.search-input-filled {
    label {
      opacity: 0;
    }
  }
}

@include breakpoint('md') {
  .header {
    padding: 30px 5px 130px;
  }

  .header-logo {
    margin: 0 auto;
    text-align: center;
    width: 290px;
    
    img {
      width: 100%;
    }
  }

  #toggle-hamburger {
    display: none;
  }

  .header-nav {
    text-align: center;
    position: absolute;
    width: 800px;
    left: 50%;
    margin-left: -400px;
    top: 145px;
  
    &.fixed {
      position: fixed;
      top: 0;
      z-index: 999;
    }

  
    .header-mainnav {
      padding: 0 120px 0 70px;
      border-radius: 14px;
      background: $grey;
      display: inline-block;
      position: relative;
      @include box-shadow;
      overflow: visible;
    }
  
    .header-mainnav-li {
      display: inline-block;
  
      a {
        font-weight: 600;
        padding: 0 15px;
        line-height: 64px;
        height: 64px;
        border-bottom: 2px solid $grey;
        font-size: $font-size;
        display: inline-block;
        margin: 0;
        color: $grey-dark;
  
        &:hover {
          text-decoration: none;
          border-bottom-color: $grey-dark;
          background-color: $grey-highlight;
        }
      }
  
      &.active {
        a {
          border-bottom-color: $red;
          text-decoration: none;
  
          &:hover {
            border-bottom-color: $grey-dark;
          }
        }
      }
  
      &.has-subnav {
        position: relative;
  
        &:hover {
          .header-subnav {
            display: inline-block;
          }
        }
      }
    }
  
    .header-subnav {
      display: none;
      list-style: none;
      position: absolute;
      top: 64px;
      left: 0;
      margin: 0;
      padding: 0;
      background: $grey;
      min-width: 100%;
      text-align: left;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      @include box-shadow;
      padding-bottom: 10px;
      border-top: 1px solid $grey-highlight;
  
      li {
        border-bottom: 1px solid $grey-highlight;
      }
  
      a {
        white-space: nowrap;
        padding: 0 15px;
        line-height: 42px;
        height: 42px;
        display: block;
        border-bottom: none;
        border-left: 2px solid $grey;
  
        &:hover {
         border-left-color: $grey-dark;
        }
      }
    }
  
    .nav-icon {
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      width: 45px;
      @include transition();
  
      a {
        height: 100%;
        width: 45px;
        color: $grey;
        background: $grey;
        padding: 15px 5px;
      }
  
      span {
        opacity: 0;
      }
    }
  
    .nav-home {
      z-index: 1;
      left: 20px;
      right: auto;
      a {
        background: url("/assets/icons/home.png") 50% 50% no-repeat;
        background-size: 20px;
      }
    }
  
    .nav-search {
      z-index: 2;
      right: 70px;
      a {
        background: url("/assets/icons/search.png") 50% 50% no-repeat;
        background-size: 20px;
      }
    }
  
    .nav-facebook {
      z-index: 1;
      a {
        background: url("/assets/icons/facebook.png") 50% 50% no-repeat;
        background-size: 20px;
      }
    }
  
    .searchform {
      display: none;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: left;
      padding: 0 230px 0 10px;
      border: 0;
      background: $grey-dark;

      .searchform-cancel {
        display: inline-block;
      }
  
      label {
        position: absolute;
        color: $white;
        font-size: 20px;
        font-weight: 400;
        padding: 0 10px;
        height: 40px;
        top: 50%;
        line-height: 40px;
        margin-top: -20px;
        z-index: 1;
      }
  
      input {
        position: relative;
        z-index: 2;
        background: none;
        color: $white;
        border: none;
        width: 100%;
        height: 100%;
        outline: none;
        padding: 10px 10px;
        font-size: 30px;
      }
  
      button {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100px;
        padding: 0;
        background: none;
        border: 0;
        color: $white;
        padding-top: 4px;
        border-bottom: 2px solid $grey-dark;
  
        &:hover {
          border-bottom-color: $white;
        }
      }
  
      .searchform-submit {
        right: 120px;
      }
  
      .searchform-cancel {
        right: 20px;
      }
    }
 
    &.search-active {
  
      .searchform {
        display: block;
      }
  
      .nav-search {
        background: $grey-dark;
        right: 0;
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
  
        a {
          display: none;
          border-color: none;
        }
      }
    }
  }
}
