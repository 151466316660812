.search-result {
  margin: 30px 0 30px;
}

.search-result-form {
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  @include box-shadow;
  border-radius: 5px;
}

.search-result-input,
.search-result-submit {
  color: $grey-dark;
  background: $white;
  font-size: $font-size;
  padding: 10px 15px;
  border-radius: 5px 0 0 5px;
  border: 2px solid $grey;
  border-right: 0;

  &:hover, &:focus {
    background: $grey;
    border-color: $grey;
  }
}

.search-result-submit {
  border-right: 2px solid $grey;
  border-left: 0;
  border-radius: 0 5px 5px 0;
  background: $white;

  &:hover, &:focus {
    background: $grey;
    border-color: $grey;
  }
}

.search-bottom {
  padding: 30px 0;
}