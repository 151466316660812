.comment {
  max-width: 640px;
  min-height: 100px;
  margin: 20px auto 0;
  text-align: left;
  padding: 10px 0 30px 100px;
  padding-left: 110px;
  position: relative;
  transform: translate(-20px);
}

.comment-img {
  display: inline-block;
  width: 90px;
  height: 90px;
  background: $grey-dark;
  border-radius: 80px;
  position: absolute;
  left: 0px;
  top: 0px;

  &.anna {
    background: url("/assets/anna-h.jpg");
    background-size: cover;
  }

  &.walter {
    background: url("/assets/walter-g.jpg");
    background-size: cover;
  }

  &:before {
    content: "\201C";
    font-size: 160px;
    color: $grey-dark;
    width: 80px;
    height: 80px;
    display: inline-block;
    position: absolute;
    font-family: serif;
    opacity: 0.12;
    z-index: 1;
    margin-left: 90px;
    margin-top: -65px;
  }
}

.comment-cite {
  position: relative;
  z-index: 2;
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 20px;
}

.comment-name {
  font-size: 16px;
  color: $red;
  font-weight: 600;
}

.comment-work {
  font-size: 16px;
  color: $grey-dark;
  padding-left: 12px;
}

.feature-box {
  padding: 50px 0 90px;
  text-align: center;
  background: $grey;

  &.light {
    background: $white;
  }

  h2 {
    font-size: 40px;
    color: $red;
  }
}
