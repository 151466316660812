.footer {
  font-size: 0;
  text-align: center;
  padding: 40px 0;
  color: $white;
  background: $grey-dark;
  font-size: $font-size;

  a {
    color: $white;
    text-decoration: none;
    border-bottom: 1px solid white;

    &:hover, &:focus {
      color: $white;
      text-decoration: none;
      opacity: 0.5;
    }
  }
}

.footer-nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

a.meister-der-elemente {
  background-image: url("assets/meister-der-elemente.png");
  display: inline-block;
  width: 100%;
  font-size: 0;
  height: 36px;
  border: 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
  border-bottom: none;
  margin-bottom: 30px;
}

.footer-content {
  font-size: $font-size;
  width: 100%;
  max-width: 100%;

  .col {
    padding: 20px;
  }
}
