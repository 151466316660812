.article {
  @include clearfix;
  
  h1 {
    font-size: 40px;
  }

  .article-header {
    padding-bottom: 50px;

    time {
      color: $grey-dark;
    }
  }

  .article-author {
    position: relative;
    padding: 20px 0 0 110px;
    min-height: 100px;
    margin-top: 20px;
    margin-bottom: 40px;
    color: $grey-dark;
  }

  .article-author-img {
    position: absolute;
    left: 0;
    top: 0;
    background: $grey-dark;
    width: 90px;
    height: 90px;
    border-radius: 90px;
  }

  .article-header-img {
    background: $grey-dark;
    width: 100%;
    border-radius: 12px;
    width: 100%;
    height: 340px;
    margin-bottom: 20px;
  }

  .article-text {
    padding-bottom: 50px;
  }
}

@include breakpoint('md') {
  .article .article-header-img {
    float: right;
    max-width: 420px;
    height: 340px;
    margin: 20px;
  }

  .article-text {
    width: 75%;
  }
}