.card {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  @include box-shadow;
}

.card-img {
  padding-bottom: 67%;
  background-color: $grey-dark;
  background-size: cover;
}

.card-content {
  text-align: center;
  padding: 25px 5px;
  background: $white;

  h3 {
    font-weight: 600;
  }

  a {
    color: $red;
    border-bottom: 2px solid $red;
    text-decoration: none;

    &:hover, &:focus {
      color: $grey-dark;
      text-decoration: none;
      border-color: $grey-dark;
    }
  }

  &.dark {
    background: $grey;
  }
}
