.gallery {
  background: $grey-dark;
  background: $grey;
  text-align: center;
  border-radius: 12px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  overflow: hidden;
  margin: 30px 0;

  figure {
    position: relative;
    margin: 0;
  }

  img {
    margin: 0 auto;
  }

  figcaption {
    position: absolute;
    line-height: 1;
    margin: 10px;
    bottom: 0;
    background: $white;
    color: $grey-dark;
    border-radius: 12px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
  }

  .slick-arrow {
    position: absolute;
    z-index: 99;
    font-size: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0);
    border: 0;
    outline: none;
    width: 60px;
    padding: 0;

    &:hover {
      background: rgba(255,255,255,0.3);
    }

    &:before, &:after {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      background: $white;
      border-radius: 40px;
      margin-top: -15px;
    }
  }

  .slick-prev {
    left: 0;
    &:before {
      left: 10px;
    }
    &:after {
      left: 10px;
      background: url("/assets/icons/arrow-left.png") 50% 50% no-repeat;
      background-size: 20px;
    }
  }

  .slick-next {
    right: 0;
    &:before {
      right: 10px;
    }
    &:after {
      right: 10px;
      background: url("/assets/icons/arrow-right.png") 50% 50% no-repeat;
      background-size: 20px;
    }
  }
}

.slideshow {
  border-radius: 20px;
  height: 540px;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  overflow: hidden;


  &.changing {
    .slide-card {
      opacity: 0;
    }
  }

  .slide {
    position: relative;
  }

  .slide-1 {
    background: url("/assets/badezimmer-einfamilienhaus.jpg");
    background-size: cover;
  }

  .slide-2 {
    background: url("/assets/lueftungsrohre.jpg");
    background-size: cover;
  }

  .slide-3 {
    background: url("/assets/Holz_mit_Landschaft.jpg");
    background-size: cover;
  }

  .slick-list,
  .slick-track,
  .slick-slide
  {
    height:100% !important;
  }

  .slick-slide > div
  {
    height:100%
  }
  .slick-arrow {
    position: absolute;
    z-index: 99;
    font-size: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0);
    border: 0;
    outline: none;
    width: 60px;
    padding: 0;

    &:hover {
      background: rgba(255,255,255,0.3);
    }

    &:before, &:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      background: $white;
      border-radius: 40px;
    }
  }

  .slick-prev {
    left: 0;
    &:before {
      left: 10px;
    }
    &:after {
      left: 10px;
      background: url("/assets/icons/arrow-left.png") 50% 50% no-repeat;
      background-size: 20px;
    }
  }

  .slick-next {
    right: 0;
    &:before {
      right: 10px;
    }
    &:after {
      right: 10px;
      background: url("/assets/icons/arrow-right.png") 50% 50% no-repeat;
      background-size: 20px;
    }
  }
}

.slide-card {
  max-width: 340px;
  background: $white;
  padding: 30px;
  position: absolute;
  height: auto;
  bottom: 60px;
  border-radius: 20px;
  opacity: 1;
  transition: opacity 0.1s ease;
  margin: 0 60px;

  a {
    color: $red;
    text-decoration: none;
    border-bottom: 2px solid $red;
  }
}

@include breakpoint('md') {
  .slide-card {
    right: 90px;
  }
}